import { useEffect, useState } from "react";
import Error from "./common/Error";
import Result from "./common/Result";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "../Util";
import Layout from "./common/Layout";
import Loader from "./common/Loader";
import {POST, callApi, handleException } from "../store/Actions";
import { useTranslation } from "react-i18next";
import { store } from "../store";

const OtpCallback = () => {

  let [status, setSetatus] = useState(undefined);
  let [callbackUrl, setCallbackUrl] = useState(undefined);
  let [params] = useSearchParams();

  const { t } = useTranslation();
  
  useEffect (() => {
    const {transactionId} = store.getState().Reducer
    callApi(POST, '/transactions/'+ transactionId +'/otp/verification/callback',
        {"otpCode" : params.get("otpCode")})
    .then ((response) => {
        setSetatus (() => response.data.status)
        setCallbackUrl(() => response.data.callbackUrl)
    })
    .catch ((err) => { handleException(err)})
  }, [])

  return (
      <Layout>
          <Loader loading={isEmpty(status)} message={t('otpCallback.loading.text')}/>
          <Error message={t('otpCallback.error.text')} />
          <Result status={status} 
            successMessage={t('otpCallback.success.text') + ' ' + t('otpCallback.success.back.text')}
            failureMessage={t('otpCallback.failure.text')}/>
          {
            ! isEmpty(callbackUrl) && 
            <div className="mt-4">
              <a href={callbackUrl} className="az-link">
                <img src={process.env.PUBLIC_URL + 'images/arrow-down-right.png'}
                        srcSet={process.env.PUBLIC_URL + 'images/arrow-down-right@2x.png 2x,'
                                + process.env.PUBLIC_URL + 'images/arrow-down-right@3x.png 3x'}
                        alt="goBack"
                        className="arrow_down-right"/>
                <span className="az-back-label">{t('otpCallback.back.link')}</span> 
              </a>
            </div>
          }
      </Layout>
    );
  }

export default OtpCallback;