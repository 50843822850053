
import { useTranslation } from "react-i18next";
import Layout from "./common/Layout";
import Error from "./common/Error";
import Back from "./common/Back";

const NotFound = () => { 

  const { t } = useTranslation();
  return ( 
    <>
      <Layout isLarge={false}>  
        <Error renderer={true} message="Page not found ! "/>
        <Back renderer={true} message={t('app.back.link')} />
      </Layout>
    </>
  );
}

export default NotFound;