
import { isEmpty } from "../../Util"
import { useSelector } from "react-redux";

const Back = ({renderer, message}) => {

    const backUrl = useSelector((state) => state.Reducer.backUrl)

    return (
        !isEmpty(backUrl) && renderer &&
        <div className="mt-4">
            <a href={backUrl} className="az-link" > 
                <img src={process.env.PUBLIC_URL + 'images/arrow-down-right.png'}
                        srcSet={process.env.PUBLIC_URL + 'images/arrow-down-right@2x.png 2x,'
                                + process.env.PUBLIC_URL + 'images/arrow-down-right@3x.png 3x'}
                        alt="goBack"
                        className="arrow_down-right"/>
                <span className="az-back-label">{message}</span> 
            </a>
        </div>
    );
}
export default Back;