import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap'

import CheckCard from './CheckCard';
import Error from "./common/Error";

import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';
import { contains, floorTens, isEmpty } from "../Util";
import Layout from "./common/Layout";
import { cancelTransaction, fetchResources, setTransactionAgreement } from "../store/Actions";
import Loader from "./common/Loader";

const CheckOptions = () => {

    const [isResourceLoaded, setIsResourceLoaded] = useState(false)
    const [showGdprMessageMandatory, setShowGdprMessageMandatory] = useState(undefined)

    const error = useSelector((state) => state.Reducer.error)
    const options = useSelector((state) => state.Reducer.options)
    const termsOfPayment = useSelector((state) => state.Reducer.settings?.termsOfPayment)
    const backUrl = useSelector((state) => state.Reducer.backUrl)
    const IsGDPRAccepted = useSelector((state) => state.Reducer.IsGDPRAccepted)
    const isTransactionStarted = useSelector((state) => state.Reducer.isTransactionStarted)

    useEffect(() => {
        if (isEmpty(options))
            fetchResources(() => {setIsResourceLoaded(true)})
        else 
            setIsResourceLoaded(() => true)
    }, [])

    useEffect(() => {      
        window.addEventListener("beforeunload", cancelTransaction);
        return () => window.removeEventListener("beforeunload", cancelTransaction);
    }, []);

    const handleGdprAgreement = (event) => {
        event.target.disabled = true
        setShowGdprMessageMandatory(false)
        setTransactionAgreement(() => event.target.disabled = false)
    }

    const { t } = useTranslation();

    return (
        <>
            <Layout isLarge={true}>
            <div className="az-pay-title">
                {t("checkOptions.verify.title")}
            </div>

            { isTransactionStarted &&
                <div className="az-pay-subtitle">    
                    {t("checkOptions.termsOfPayment.text", { termsOfPayment : floorTens(termsOfPayment) })}
                </div>
            }              

            {isResourceLoaded && isTransactionStarted && 
                <div className="row mb-5">
                    <input type="checkbox" id="gdpr-agreement-id" className="az-pay-CB" 
                        defaultChecked={IsGDPRAccepted} onChange={handleGdprAgreement}/>
                    <label className="az-pay-agreement-label" htmlFor="gdpr-agreement-id">
                        {t("checkOptions.gdpr.agreement1")}<br />
                        {t("checkOptions.gdpr.agreement2")}<br />
                        <span className="az-pay-notice">
                            <a href={process.env.REACT_APP_PRIVACY_NOTICE} target="_blank" rel="noreferrer"> 
                                {t("checkOptions.gdpr.agreement3")}
                            </a>
                    </span>
                    </label>
                </div>
            }

            <Loader loading={!isResourceLoaded} message={t('checkOptions.loading.text')}/>

            { showGdprMessageMandatory && 
                <div className="small-font error-message">
                    {t('checkOptions.gdpr.requiredMessage')}
                </div>
            }

            <Error message={t('checkOptions.error.text')}/>

            { isResourceLoaded && isEmpty(error) && !isEmpty(options) && options.length > 1 &&
                <div className="row justify-content-md-center">
                    <CheckCard renderer={contains(options, "IdCheck")}
                        name={t("checkOptions.card.IdVerification.title")} 
                        description={t("checkOptions.card.IdVerification.desc")} 
                        recommended={true}
                        navigateTo="/IdentityCheck"
                        isActive={IsGDPRAccepted}
                        handleMandatoryMessage={() => setShowGdprMessageMandatory(true)}/>

                    <CheckCard renderer={contains(options, "OpenBanking")}
                        name={t("checkOptions.card.openBanking.title")} 
                        description={t("checkOptions.card.openBanking.desc")} 
                        recommended={true}
                        navigateTo="/OpenBankingStart"
                        isActive={IsGDPRAccepted} 
                        handleMandatoryMessage={() => setShowGdprMessageMandatory(true)}/>
                    
                    <CheckCard renderer={contains(options, "BankVerification")}
                        name={t("checkOptions.card.bankingTransfer.title")} 
                        description={t("checkOptions.card.bankingTransfer.desc")} 
                        isActive={IsGDPRAccepted} 
                        handleMandatoryMessage={() => setShowGdprMessageMandatory(true)}/>
                </div>  
            }  
            { isResourceLoaded && !isEmpty(backUrl) &&
                <div className="mt-4">
                    <a href={backUrl} className="az-link" > 
                        <img src={process.env.PUBLIC_URL + 'images/arrow-down-right.png'}
                                srcSet={process.env.PUBLIC_URL + 'images/arrow-down-right@2x.png 2x,'
                                        + process.env.PUBLIC_URL + 'images/arrow-down-right@3x.png 3x'}
                                alt="goBack"
                                className="arrow_down-right"/>
                        <span className="az-back-label">{t('checkOptions.back.link')}</span> 
                    </a>
                </div>
            }
            </Layout>

        </>

    );
  }

export default CheckOptions;