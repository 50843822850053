import { useSelector } from "react-redux";
import { isEmpty } from "../../Util"
import { useTranslation } from "react-i18next";


const Error = ({renderer, message}) => {

    const error = useSelector((state) => state.Reducer.error)
    const isTransactionStarted = useSelector((state) => state.Reducer.isTransactionStarted)

    const { t } = useTranslation()

    return (
        (renderer || !isEmpty(error)) && 
        <div className="az-pay-error az-center"> 
            <img src={process.env.PUBLIC_URL + 'images/error.png'}
                            srcSet={process.env.PUBLIC_URL + 'images/error@2x.png 2x,'
                                    + process.env.PUBLIC_URL + 'images/error@3x.png 3x'}
                            alt="error"
                            className="result" />
            <div className="text-center error-message">{isTransactionStarted ? message : t('app.transaction.expired.text')}</div>
        </div>
    );
}
export default Error;