import {useEffect, useState } from "react";
import Error from "./common/Error";
import { Link } from "react-router-dom";
import Layout from "./common/Layout";
import { useTranslation } from "react-i18next";
import {handleException, verifyTransaction, GET, callApi, cancelTransaction} from "../store/Actions";
import { store } from "../store";
import Loader from "./common/Loader";
import { fetchWithRetry, isEmpty } from "../Util";

const OtpCheck = () => {
  const [backUrl, setBackUrl] = useState(undefined)
  const [isResendOtp, setIsResendOtp] = useState(false)
  const [status, setStatus] = useState(undefined)
  const  { t } = useTranslation()

  const resendOtpCode = async () => {
    setIsResendOtp(false)
    verifyTransaction(() => setIsResendOtp(true))  
  };

  useEffect(() => {      
    window.addEventListener("beforeunload", cancelTransaction);
    return () => window.removeEventListener("beforeunload", cancelTransaction);
  }, []);

  useEffect(() => {
    const transactionId = store.getState().Reducer?.transactionId

    callApi(GET, '/transactions/'+ transactionId)
      .then ((response) => {
        setBackUrl(() => response.data.backUrl)
      })
      .catch ((err) => { handleException(err)})

    fetchWithRetry('/transactions/' + transactionId + '/status',
      (s) => setStatus(s), () => handleException(), 1000, 5000, 1000)
  }, [])
  
  return (
      <Layout>

        <div className="az-pay-title">
            {t("otpCheck.title")}
        </div>

        <div className="az-pay-subtitle mb-5">
            {t("otpCheck.message")}
        </div> 

        <Error message={t('otpCheck.error.text')} />

        { (isEmpty(status) || status === 'Started') &&
          <div className="mb-5">
            <Loader loading={true} message={t('otpCheck.loading.text')}/>
          </div>
        }

        { isResendOtp &&
            <div className="row az-pay-message-success">
              {t("otpCheck.resend.message")}
            </div>
        }

        <Link className="pay-card-link" onClick={() => resendOtpCode()}> 
          <div className="row justify-content-md-center mt-3">
            <div className="az-pay-select-btn">
              <span className="Etape-suivante">
              {t("otpCheck.resend.btn")}
              </span>
            </div>
          </div>
        </Link>
        {
          ! isEmpty(backUrl) &&
          <div className="mt-4">
            <a href={backUrl} className="az-link">
              <img src={process.env.PUBLIC_URL + 'images/arrow-down-right.png'}
                   srcSet={process.env.PUBLIC_URL + 'images/arrow-down-right@2x.png 2x,'
                     + process.env.PUBLIC_URL + 'images/arrow-down-right@3x.png 3x'}
                   alt="goBack"
                   className="arrow_down-right"/>
              <span className="az-back-label">{t('app.back.link')}</span>
            </a>
          </div>
        }
      </Layout>
    );
  }

export default OtpCheck;