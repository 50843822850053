import { useEffect, useState } from "react";
import { fetchWithRetry, isEmpty, sleep } from "../Util";

import Error from "./common/Error";
import { store } from "../store";
import Result from "./common/Result";
import Layout from "./common/Layout";
import { useTranslation } from "react-i18next";
import Loader from "./common/Loader";
import { POST, callApi, cancelTransaction, handleException } from "../store/Actions";
import { useSelector } from "react-redux";
import Back from "./common/Back";


const IdentityCheck = () => {

  let [onfidoLoading, setOnfidoLoading] = useState(true);
  let [onfidoCompleted, setOnfidoCompleted] = useState(false);
  let [status, setStatus] = useState(undefined);

  const error = useSelector((state) => state.Reducer.error)
  const isWhiteLabel = useSelector((state) => state.Reducer.settings?.isWhiteLabel)

  const { t, i18n } = useTranslation();

  useEffect(() => {      
    window.addEventListener("beforeunload", cancelTransaction);
    return () => window.removeEventListener("beforeunload", cancelTransaction);
}, []);

  useEffect(() => {
    const transactionId = store.getState().Reducer?.transactionId
    callApi(POST, '/verification/idchecks/start', {"transactionId" : transactionId, "status" : "Accepted"})
    .then ((response) => {
      let sdkToken  = response.data.sdkToken;
      let runId = response.data.runId;
      setOnfidoLoading(false);
      if (!isEmpty(sdkToken)) {
        window.Onfido.init({
          token: sdkToken,
          containerId: 'onfido-mount',
          workflowRunId: runId,
          language: i18n.language,
          onComplete: function (data) {
            setOnfidoCompleted(true)
            window.removeEventListener("beforeunload", cancelTransaction)
            sleep(4000).then(id => {
              fetchWithRetry('/verification/idchecks/callback',
                (status) => { setStatus(status) }, 
                () => { handleException()}, 500, 500)
              clearTimeout(id)
            })

          }
        });
      } else { handleException() } 
    })
    .catch((err) => handleException(err))
  }, []);

  return (    
      <>
        <Layout centerLogo={true}>
          <div className="az-pay-title">
            {t('idcheck.title')}
          </div>
          {
            (onfidoLoading || onfidoCompleted) &&
            <>
              <Loader loading={isEmpty(status)}  message={ onfidoLoading ? 
                  t('idcheck.loading.text') : t('idcheck.completion.loading.text')}/>
              <Result status={status} 
                  successMessage={t('idcheck.completion.success.text') + ' ' + t('idcheck.completion.success.back.text')} 
                  failureMessage={t('idcheck.completion.failure.text')}
                  mailTo={isWhiteLabel}/>
            </>
          }
          <Error message={t('idcheck.error.text')} />
          <div className="row az-center">
              {!onfidoCompleted && isEmpty(error) && <div id="onfido-mount" /> }
          </div>

          <Back renderer={!onfidoLoading} message={t('idcheck.back.link')} />
        </Layout>
      </>
    );
  }

export default IdentityCheck;