import { isEmpty } from "../../Util"
import { useSelector } from "react-redux"

const Result = ({status, successMessage = '', failureMessage = '', mailTo}) => {

    const error = useSelector((state) => state.Reducer.error)

    return (
        isEmpty(error) && !isEmpty(status) && 
        <div className="az-pay-success az-center"> 
            { (status === 'Succeeded' || status === 'Accepted') ?
                <>
                    <img src={process.env.PUBLIC_URL + 'images/success.png'}
                                srcSet={process.env.PUBLIC_URL + 'images/success@2x.png 2x,'
                                        + process.env.PUBLIC_URL + 'images/success@3x.png 3x'}
                                alt="success"
                                className="result" />
                    <div className="success-message">{successMessage}</div>
                </> 
            : 
                <>
                    <img src={process.env.PUBLIC_URL + 'images/error.png'}
                                srcSet={process.env.PUBLIC_URL + 'images/error@2x.png 2x,'
                                        + process.env.PUBLIC_URL + 'images/error@3x.png 3x'}
                                alt="error"
                                className="result" />
                    <div className="text-center success-message">
                        {failureMessage + ' '}  
                        {mailTo && <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}>{process.env.REACT_APP_CONTACT_EMAIL}</a>} 
                    </div>
                </>
            }    
        </div>    
    );
}
export default Result;