import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'; 
import { PersistGate } from 'redux-persist/integration/react';
import { Suspense } from 'react';
import  {store, persistedStore} from './store';
import './style.css';

import 'flag-icon-css/css/flag-icons.min.css'
import App from './App';
import Loader from './components/common/Loader';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
      <Suspense fallback={<div className='container az-center'><Loader loading={true}/></div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      </Suspense>
      </PersistGate>
    </Provider>
  </> 
);