import React from "react";
import { isEmpty } from "../../Util";
import { useSelector } from "react-redux";

const Layout = (prop) => {

    const isWhiteLabel = useSelector((state) => state.Reducer.settings?.isWhiteLabel)

    return (
        <>
            <div className={prop.isLarge ? "container large" : "container"}>
                <main role="main">
                    { 
                        (!prop.hideLogo && !isEmpty(isWhiteLabel) && !isWhiteLabel) &&
                        <div className={prop.centerLogo ? 'text-center' : ''}>
                            <img src={process.env.PUBLIC_URL + 'images/allianz-trade-pay-final.png'}
                                srcSet={process.env.PUBLIC_URL + 'images/allianz-trade-pay-final@2x.png 2x,'
                                        + process.env.PUBLIC_URL + 'images/allianz-trade-pay-final@3x.png 3x'}
                                alt="Allianz trade"
                                className={prop.centerLogo ? 'az-pay-center' : 'az-pay-left'} />
                        </div>
                    }
                    {prop.children}
                </main>
            </div>
        </>
    )
}

export default Layout;