import { useEffect, useState } from "react";
import Error from "./common/Error";
import { fetchWithRetry, isEmpty } from "../Util";
import { useSearchParams } from "react-router-dom";
import Result from "./common/Result";
import Layout from "./common/Layout";
import Loader from "./common/Loader";
import { useTranslation } from "react-i18next";
import { handleException } from "../store/Actions";
import { useSelector } from "react-redux";
import Back from "./common/Back";

const OpenbankingCallback = () => {

  let [status, setStatus] = useState(undefined);
  let [params] = useSearchParams();

  const isWhiteLabel = useSelector((state) => state.Reducer.settings?.isWhiteLabel)
  const error = useSelector((state) => state.Reducer.error)

  const { t } = useTranslation()

  useEffect(() =>  {
    fetchWithRetry('/verification/openbanking/callback',  
      (status) => setStatus(status), 
      () => handleException(), 500, 500)
  }, [params])

  return (    
    <Layout  isLarge={false}>
        <div className="az-pay-title">
          {t("openBankingCallback.title")}
        </div>
        <Loader loading={isEmpty(status)} message={t('openBankingCallback.loading.text')} />
        <Error message={t('openBankingCallback.error.text')} />
        <Back renderer={!isEmpty(error)} message={t('app.back.link')} />
        <Result status={status} 
          successMessage={t('openBankingCallback.success.text') + ' ' + t('openBankingCallback.success.back.text')} 
          failureMessage={t('openBankingCallback.failure.text')}
          mailTo={!isWhiteLabel} />
    </Layout>
  );
}

export default OpenbankingCallback;