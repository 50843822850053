import { useEffect } from "react";
import { isEmpty, sleep } from "../Util";

import Error from "./common/Error";
import Layout from "./common/Layout";
import Loader from "./common/Loader";
import { POST, callApi, handleException } from "../store/Actions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Back from "./common/Back";

const OpenbankingStart = () => {

  const { t } = useTranslation();
  const error = useSelector((state) => state.Reducer.error)
  const transactionId = useSelector((state) => state.Reducer.transactionId)

  useEffect(() => {
    callApi(POST, '/verification/openbanking/start', 
        {'transactionId' : transactionId})
    .then ((response) => {
      var redirectUrl = response.data.redirectUrl 
      if (!isEmpty(redirectUrl)) {
        sleep(2000)
        .then((t) => clearTimeout(t))
        .then(() => window.location.replace(redirectUrl))
      } else {
        handleException();
      }
    }).catch (() => { handleException(); })
  },[]);

  return (    
    <Layout centerLogo={true}>

      <div className="az-pay-title">
        {t("openBankingStart.title")}
      </div>
      <Loader loading={true} message={t('openBankingStart.loading.text')}/>
      <Error message={t('openBankingStart.error.text')}/>
      <Back renderer={!isEmpty(error)} message={t('app.back.link')} />
    </Layout>
  );
}

export default OpenbankingStart;