export const languages = [
  {
    code: 'en',
    name: 'English',
    countryCode: 'gb'
  }, 
  {
    code: 'fr',
    name: 'Français',
    countryCode: 'fr'
  },
  {
    code: 'de',
    name: 'Germany',
    countryCode: 'de'
  },
  {
    code: 'nl',
    name: 'Netherlands',
    countryCode: 'nl'
  },
  {
    code: 'it',
    name: 'Italian',
    countryCode: 'it'
  },
  {
    code: 'es',
    name: 'Spanish',
    countryCode: 'es'
  }
]