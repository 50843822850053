import i18next from "i18next"
import {initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"
import axios from "axios"
import { store } from "./store";

const loadResources = async (lng, ns = 'default') => {
  const localePath = `/locales/${lng}.json`
  let  locales = (await axios.get(localePath, {  headers: { "Access-Control-Allow-Origin": "*" }, })).data
  try {
    const token = store.getState().Reducer.token
    const remoteLocalePath = `${process.env.REACT_APP_PROXY_SIC_URL}/customerSettings/localizedTexts?languageCode=${lng}&domain=OffsiteRedirect`
    const remoteLocales = await axios.get(remoteLocalePath, {  headers: { "token": token }, }) 
    locales =  {...locales, ...remoteLocales.data}
  } catch (err) {}

  return locales
};

 
const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      let [lng] = url.split("|");
      loadResources(lng).then((response) => {
        callback(null, { data: response,  status: 200,  });
      });
    } catch (e) {
      callback(null, { status: 500,  });
    }
  },
};

export const initI18next = (callback, lng = 'en') => {
  i18next
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: lng,
    backend: backendOptions,
    fallbackLng: "en",
    debug: false,
    ns: ["default"],
    defaultNS: "default",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
  }).finally(() => {
    callback()  
  });
} 