import { FadeLoader } from "react-spinners";
import { isEmpty } from "../../Util"
import { useSelector } from "react-redux";

const Loader = ({loading, message}) => {
    
    const error = useSelector((state) => state.Reducer.error)

    return (
        isEmpty(error) && loading &&
        <div className="az-pay-card card-max-width card">
            <div className="row az-center pt-4">
                <FadeLoader color="#0065ae"/>
            </div>
            <div className="row az-center">
                <span className="az-pay-loadingMessage ">{!isEmpty(message) && message }</span>
            </div>
        </div>
    );
}
export default Loader;