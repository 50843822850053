import {  GET_QUOTE, INIT_STATE,  SET_AGREEMENT,  SET_ERROR,  SET_OPTIONS,  SET_SETTINGS, SET_TRANSACTION } from "./Actions";

const initialState = {
    token : "",
    transactionId : "",
    settings : "",
    options : [],
    isTransactionStarted : true,
    error : ""
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_STATE : 
        case SET_SETTINGS : 
        case SET_OPTIONS : 
        case SET_TRANSACTION : 
        case SET_AGREEMENT :
        case GET_QUOTE :
        case SET_ERROR : 
            return { ...state, ...action.payload}
        default : 
            return state;
    }
}

export { initialState, Reducer } ;