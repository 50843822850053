import axios from 'axios'
import { store } from './index'
import { initialState } from './Reducers'
import { equalsIgnoreCase, isEmpty } from '../Util'
import { initI18next } from '../i18n'
import { languages } from '../i18n-languages'

const INIT_STATE = "INIT_STATE"
const SET_OPTIONS = "GET_OPTIONS"
const SET_SETTINGS = "SET_SETTINGS"
const GET_QUOTE = "GET_QUOTE"
const SET_TRANSACTION = "SET_TRANSACTION"
const SET_AGREEMENT = "SET_AGREEMENT"
const SET_ERROR = "SET_ERROR"

const GET = 'Get'
const POST = 'Post'
const PATCH = 'Patch'

const sicProxyUrl = process.env.REACT_APP_PROXY_SIC_URL

const initStore = async (params, callback) => {   
  try {
    await initState(params);
    let resp = await callApi(GET, '/purchaser/settings');
    store.dispatch({type: SET_SETTINGS, payload : {settings : resp.data}})
    const transactionId = store.getState().Reducer?.transactionId
    resp = await callApi(GET, '/transactions/' + transactionId)
    store.dispatch({type: SET_TRANSACTION, payload: {'backUrl': resp.data.backUrl, 
                'IsGDPRAccepted': resp.data.IsGDPRAccepted, 
                'isTransactionStarted': resp.data.status === 'Started'}})
  } catch (err) {       
    handleException(err)
  } finally {
    const language = languages.find(lng => equalsIgnoreCase(lng.code, store.getState().Reducer.settings?.languageCode))
    const languageCode = isEmpty(language) ? 'en' : language.code
    initI18next(callback, languageCode)
  }
}

const initState = (params) => {
  return new Promise((resolve) => {
    const token = params.get("token");
    if (!isEmpty(token)) {
      const transactionId = params.get("transactionId")
      store.dispatch({ type: INIT_STATE, payload : { ...initialState, token, transactionId }});
    }
    return resolve();
  })
}

const fetchResources = async (callback) => {
  try {
    let resp = await callApi(GET, '/purchaser/strongverification/options')
    store.dispatch({type: SET_OPTIONS, payload: { options : resp.data }})
  } catch (err) {
    handleException(err)
  } finally {
    callback()
  }
}

const getQuote = async (sessionToken, setRequirement, callback) => {
    try {
      const transactionId = store.getState().Reducer?.transactionId
      if (!isEmpty(transactionId)) {
        const resp = await callApi(POST, '/coverage/transactions',
          {
            transactionId : transactionId,
            sessionToken : sessionToken
          })
        const {requirement, callbackUrl, status, rejectionReason} = resp.data
        if (isEmpty(requirement) && isEmpty(callbackUrl))
          handleException()
        setRequirement(requirement, callbackUrl, status === 'Refused', rejectionReason === 'TOO_MANY_ATTEMPTS')
      }
    } catch (err) {
      handleException(err)
    } finally {
      callback()
    }
}

const activateCover = async (status) => {
  try {
    if (status === 'Succeeded' || status === 'Accepted') {
      const shouldActivate = store.getState().Reducer?.settings?.shouldActivateCoverAfterFraudValidation 
      if (!isEmpty(shouldActivate) && shouldActivate) {
        const transactionId = store.getState().Reducer?.transactionId
        await callApi(PATCH, '/transactions/'+ transactionId +'/coverage/activate', null, false)
      }
    }
  } catch (err) {
    throw new Error();
  }
}

const verifyTransaction = async (callback) => {
  try {
    const transactionId = store.getState().Reducer?.transactionId
    await callApi(POST, '/transactions/'+ transactionId + '/verify')
    callback()
  } catch (err) {
    handleException(err)
  }
} 

const setTransactionAgreement = async (callback) => {
  try {
    const transactionId = store.getState().Reducer?.transactionId
    const IsGDPRAccepted = store.getState().Reducer?.IsGDPRAccepted
    await callApi(PATCH, '/transactions/'+ transactionId +'/agreement', {"isAccepted" : !IsGDPRAccepted})
    store.dispatch({type: SET_AGREEMENT, payload: { IsGDPRAccepted : !IsGDPRAccepted}})
  } catch (err) {
    handleException(err)
  } finally {
    callback();
  }
}

const cancelTransaction = async (event) => {
  try {
    const transactionId = store.getState().Reducer?.transactionId
    if (!isEmpty(transactionId) && store.getState().Reducer?.isTransactionStarted)
      await callApi(PATCH, '/transactions/'+ transactionId +'/cancel')
  } catch (err) {
    handleException(err)
  } 
}

const callApi = async (method, service, data = null, shouldCheckTransaction = true) => {
  const isTransactionStarted = store.getState().Reducer?.isTransactionStarted
  if (shouldCheckTransaction && !isTransactionStarted)
    throw new Error('403', 'operation not allowed')
  return await axios({
    method: method,
    url : sicProxyUrl + service,
    headers : { 'token' : store.getState().Reducer?.token },
    data : data
  })
}

const handleException = (err) => {
  if (err?.response?.status === 401)
    store.dispatch({type: SET_TRANSACTION, payload: {'isTransactionStarted': false}})
  store.dispatch({type: SET_ERROR, payload: { error : 'A technical problem does not allow access to the verification service.'}})
}

export {initStore, fetchResources, getQuote, activateCover, verifyTransaction, setTransactionAgreement, cancelTransaction, handleException, callApi,
  SET_SETTINGS, INIT_STATE, SET_OPTIONS, GET_QUOTE, SET_TRANSACTION, SET_AGREEMENT, SET_ERROR, GET, POST, PATCH}