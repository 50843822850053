
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CheckCard = ({renderer = true, name, description, recommended, navigateTo, isActive, handleMandatoryMessage}) => {
  
  const { t } = useTranslation();

  const checkActivation = () => {
    if (!isActive) {
      handleMandatoryMessage()
    }
  }

  return (
    renderer && 
        <div className="col-lg-4 mb-3 card-max-width d-flex align-items-stretch">
            <div className="az-pay-card card-max-width card">
                { recommended &&
                  <div className="az-pay-recommended-btn text-center">
                    <span>{t("checkOptions.card.recommended.label")}</span>
                  </div>
                }
                <div className="az-pay-card-title"> 
                    {name} 
                </div>

                <div className="description-long az-pay-description">{description}</div>

                <Link to={isActive ? navigateTo : ''} className="pay-card-link" 
                    onClick={() => checkActivation()}> 
                  <div className="row justify-content-md-center">
                    <div className={(isActive ? '' : 'az-disabled ') + 'az-pay-select az-pay-select-btn'}>
                      <span className="Etape-suivante">
                        Select
                      </span>
                    </div>
                  </div>
                </Link>

            </div>
        </div>
  );

}
export default CheckCard;
